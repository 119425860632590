<template>
  <AppLayout>
    <main>
      <div
        class="page-header pb-10 page-header-dark bg-gradient-primary-to-secondary"
      >
        <div class="container-fluid">
          <div class="page-header-content">
            <h1 class="page-header-title">
              <div class="page-header-icon"><i data-feather="filter"></i></div>
              <span>Users</span>
            </h1>
            <div class="page-header-subtitle ml-2">All registered users</div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-n10">
        <div class="card mb-4">
          <div class="card-header">
            Users
            <router-link to="/users/newuser" class="btn btn-primary ml-auto"
              >Create User</router-link
            >
          </div>
          <div class="card-body">
            <div v-if="pageLoading && users.length == 0">
              <img
                style="width: 30%"
                class="d-block mx-auto"
                src="@/assets/loader.gif"
              />
            </div>
            <div v-else-if="users.length == 0">
              <img
                style="width: 30%"
                class="d-block mx-auto"
                src="@/assets/nodata.svg"
              />
              <p class="text-center">
                No data currently available
                <router-link to="/users/newuser">Create New User</router-link>
                to continue
              </p>
            </div>
            <div
              v-else-if="users.length !== 0"
              class="datatable table-responsive"
            >
              <table
                class="table table-bordered table-hover"
                id="dataTable"
                cellspacing="0"
                width="100%"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <!-- <th>Company</th> -->
                    <th>Role</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <!-- <th>Company</th> -->
                    <th>Role</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </tfoot>
                <tbody>
                  <tr v-for="user in users" :key="user.id">
                    <td>{{ user.name }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.phoneNumber }}</td>
                    <!-- <td>{{ user.company }}</td> -->
                    <td>{{ user.role }}</td>
                    <td>
                      <div class="badge badge-primary badge-pill">
                        {{ user.status }}
                      </div>
                    </td>
                    <td>
                      <router-link
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit User"
                        class="btn btn-datatable btn-transparent-dark mr-2"
                        :to="{ name: 'EditUser', params: { id: user.id } }"
                      >
                        <i
                          class="bi bi-pencil"
                          style="font-size: 1rem"
                        ></i></router-link
                      ><button
                        data-toggle="tooltip"
                        title="Deactivate"
                        class="btn btn-datatable btn-icon btn-transparent-dark"
                      >
                        <i class="bi bi-x-circle" style="font-size: 1rem"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue";
import { mapState, mapActions } from "pinia";
import { useAdminStore } from "@/stores/admin";

import $ from "jquery";

export default {
  name: "Users",
  components: { AppLayout },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    ...mapState(useAdminStore, ["pageLoading", "users"]),
  },

  methods: {
    ...mapActions(useAdminStore, ["getAllUsers"]),
  },

  mounted() {
    this.getAllUsers().then(() => {
      $(document).ready(function () {
        $("#dataTable").DataTable();
      });
    });
  },
};
</script>
